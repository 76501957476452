let headerBurger = document.getElementById("header-burger");
let modal = modal = document.getElementById("modal");
let closeButtom = document.getElementById("close-modal");
let navlinks = document.getElementsByClassName("nav-link-modal");

headerBurger.onclick = function() {
    modal.style.display = "flex";
}

closeButtom.onclick = function() {
    modal.style.display = "none";
}

for (let i = 0; i < navlinks.length; i++) {
    navlinks[i].onclick = function() {
        modal.style.display = "none";
    }
}